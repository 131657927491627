import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/workspace/src/components/layouts/MDXBlogPostLayout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const MyImage = makeShortcode("MyImage");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h4 {...{
      "id": "xed",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#xed",
        "aria-label": "xed permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`xed`}</h4>
    <p><a parentName="p" {...{
        "href": "https://man.cx/xed"
      }}>{`xed`}</a>{` is XCode's official command-line invocation tool. One would expect it to work perfectly.`}</p>
    <p>{`Unfortunately, even when xed is used in its simplest form, XCode currently happens to stall for a couple seconds or even crash when a file is opened.`}</p>
    <p>{`This is obviously an XCode bug, as the issue also arises when AppleScript is used to tell XCode to open the file.`}</p>
    <p>{`Thankfully, there is a very efficient workaround which consists in using the open command to first open the file, then AppleScript to jump to the specific line number.`}</p>
    <p>{`Here are the instructions to follow.`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Save `}<a parentName="p" {...{
            "href": "https://gist.github.com/dperetti/ebe3995a0dcc52c3239b9937da7e5cf8"
          }}>{`this gist`}</a>{` into some `}<code>{`xed.sh`}</code>{` file and `}<code>{`chmod +x`}</code>{`.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`In CodeStory preferences, create a custom editor like this:`}</p>
        <MyImage src="ide-plugins-2.png" alt="XCode settings" mdxType="MyImage">
  Custom settings in preferences
        </MyImage>
      </li>
    </ul>
    <h4 {...{
      "id": "hyperclick-on-live-code-view-tokens",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#hyperclick-on-live-code-view-tokens",
        "aria-label": "hyperclick on live code view tokens permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`Hyperclick on Live Code View tokens`}</h4>
    <p>{`As you may know, the beauty of Live Code Views is that they work both ways.`}</p>
    <p>{`All IDEs have APIs that allowed us to provide extensions that support Live Code View tokens highlighting and hyperclick to reveal related notes in CodeStory.`}</p>
    <p>{`Currently, XCode Extensions APIs are unfortunately too limited to provide such functionality.`}</p>
    <p>{`But we came up with a very acceptable workaround!`}</p>
    <p>{`CodeStory implements a custom URL scheme which can be used in addition to the command line invocation.`}</p>
    <p>{`XCode comments support URL syntax highlighting and hyperclick, but not for any scheme. It turns out that the `}<em parentName="p">{`cid://`}</em>{` url scheme is supported, although it is to our knowledge never used at system level, as `}<a parentName="p" {...{
        "href": "https://tools.ietf.org/html/rfc2392"
      }}>{`it is used to reference internal content in electronic mails`}</a>{`.`}</p>
    <p>{`CodeStory now registers `}<em parentName="p">{`cid://`}</em>{` urls scheme (along with `}<em parentName="p">{`codestory://`}</em>{`) and implements a special usage.`}</p>
    <MyImage src="ide-plugins-3.png" alt="XCode settings" mdxType="MyImage">
  XCode screenshot. Command-click on cid:// links to open the token in CodeStory!
    </MyImage>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      